import React from 'react';
import { Link } from 'react-router-dom';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer';

var bnr = require('./../../images/banner/bnr1.jpg');

const MobileDetection = ({ history }) => {
	const submitHandler = (e) => {
		e.preventDefault();
		history.push("/");
	};
	return (
		<>
			<Header />
			<div className="page-content bg-white">

				<div className="section-full content-inner-2 shop-account">
					<div className="container">

						<div className="row">
							<div className="col-lg-12">
								<div className="p-a30 border-1 max-w500 m-auto radius-sm">
									<div className="tab-content">
										<div className="form-group" style={{ textAlign: 'center' }}>
											<Link to={'/'} className="dez-page"><img src={require("./../../images/logos/app.png")} alt="" /></Link>
										</div>
										<div className="form-group" style={{ textAlign: 'center' }}>
											<h3 className="m-b5">Download Android App</h3>
											<a href='https://play.google.com/store/apps/details?id=com.velvetcream.dip&hl=en_US' className="dez-page"><img src={require("./../../images/logos/google_play.png")} alt="" /></a>
										</div>
										<div className="form-group" style={{ textAlign: 'center' }}>
											<h3 className="m-b5">Download IOS App</h3>
											<a href='https://apps.apple.com/us/app/velvet-cream/id1495798783?ls=1' className="dez-page"><img src={require("./../../images/logos/ios.png")} alt="" /></a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>

	)
}

export default MobileDetection;
